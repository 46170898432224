import React from 'react'
import styled from 'styled-components'

export const PageContainer = styled.div.attrs({
  className: 'container py-5 mb-5 bg-white px-5',
})``

export const Container = styled.div.attrs({
  className: 'container',
})``
