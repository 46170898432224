import React from 'react'
import Layout from '../components/layout'
import { PageContainer } from '../ui/Components'

const NotFoundPage = () => (
  <Layout locale="et" page={{ slug: 'error' }}>
    <PageContainer>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </PageContainer>
  </Layout>
)

export default NotFoundPage
